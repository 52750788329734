import { Box, Typography } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import { Link as LinkProps } from 'common/utils/getLink'
import React from 'react'
import { ReactComponent as ResourcesArrow } from './resourcesArrow.svg'

export interface ArticleRelevantLinkProps extends LinkProps {}

export const ArticleRelevantLink: React.FC<ArticleRelevantLinkProps> = ({
    label,
    url,
}) => {
    return (
        <Link data-testid='ArticleRelevantLink' path={url}>
            <Box
                alignItems='center'
                borderBottom={`1px solid #EBEDF7`}
                display='flex'
                minHeight={56}
            >
                <Typography
                    style={{
                        color: '#5261AC',
                        fontSize: 14,
                        fontWeight: 600,
                        flex: 1,
                        lineHeight: 1.4,
                    }}
                >
                    {label}
                </Typography>
                <ResourcesArrow style={{ marginLeft: 12.13 }} />
            </Box>
        </Link>
    )
}
