import { Box } from '@material-ui/core'
import { BlogTag } from 'common/components/molecules/BlogTag'
import { Text } from 'common/components/molecules/Text'
import moment from 'moment'
import React from 'react'

export interface ArticleHeadingProps {
    authors?: string[]
    date: Date
    image: string
    imageCaption?: string
    tag: string
    title: string
    entity: string
    readTime?: number
}

const getAuthors = (authors: string[] = []) => {
    if (!authors.length) return null
    if (authors.length === 1) return authors[0]
    return authors.join(', ')
}

export const ArticleHeading: React.FC<ArticleHeadingProps> = ({
    authors = [],
    date,
    image,
    imageCaption,
    tag,
    title,
    entity,
    readTime,
}) => {
    const authorsString = getAuthors(authors)
    return (
        <>
            <BlogTag label={tag} url={`/blog?category=${tag}`} />
            <Text label={entity} box={{ mt: 6 }} variant='subtitle1' />
            <Text box={{ mt: 8, mb: 6 }} label={title} variant='h1' />
            <Box my={6} display='flex' alignItems='center'>
                <Text
                    // box={{ my: 6 }}
                    label={moment(date).format('MMMM Do, YYYY')}
                    variant='subtitle1'
                />
                <Text
                    box={{ mx: 2 }}
                    label={`• ${readTime} min read`}
                    variant='subtitle1'
                />
            </Box>

            {authorsString && (
                <Text
                    html
                    label={`<p>Written by <strong style="color: #5261AC;">${authorsString}</strong></p>`}
                    variant='subtitle1'
                />
            )}
            <Box
                borderRadius={20}
                style={{
                    background: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
                mt={12}
                mb={7}
                height={400}
                width='100%'
            />
            {imageCaption && (
                <Text box={{ mb: 13 }} label={imageCaption} variant='caption' />
            )}
        </>
    )
}

export default ArticleHeading
