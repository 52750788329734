import { Box, Button, Grid } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import { ArticleCard, ArticleCardProps } from 'common/components/sharedLibrary'
import React, { useState } from 'react'

export interface ArticleListProps {
    articles: ArticleCardProps[]
}

export const ArticleList: React.FC<ArticleListProps> = ({ articles = [] }) => {
    const [visible, setVisible] = useState(12)
    const showLoadMore = articles.length > visible
    return (
        <Box width='100%' mt={24} component='section'>
            <Grid container spacing={6}>
                {articles.slice(0, visible).map(article => (
                    <Grid key={article.title} item xs={12} md={4}>
                        <Link path={`/blog/${article.url}`}>
                            <ArticleCard {...article} />
                        </Link>
                    </Grid>
                ))}
            </Grid>
            {showLoadMore && (
                <Box display='flex' my={9} width='100%' justifyContent='center'>
                    <Button
                        onClick={() => setVisible(visible + 12)}
                        style={{
                            border: 'none',
                            background: '#EBEDF7',
                            fontSize: 16,
                            fontWeight: 600,
                            height: 49,
                            padding: '15px 32px',
                            textTransform: 'unset',
                            borderRadius: 36,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        Load more
                    </Button>
                </Box>
            )}
        </Box>
    )
}
