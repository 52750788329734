import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'

type FilterButton = string | { label: string; value: string }

export interface ArticleFilterProps {
    buttons: FilterButton[]
    category?: string | null
    title?: string
    setCategory?: any
    subtitle?: string
}

const getButtons = (
    buttons: ArticleFilterProps['buttons']
): ArticleFilterProps['buttons'] => ['Show all', ...buttons]

export const ArticleFilter: React.FC<ArticleFilterProps> = ({
    buttons,
    category,
    title = 'Filter articles',
    setCategory,
    subtitle = 'Select one or more categories below'
}) => {
    return (
        <Box mt={18} component='section'>
            <Box mb={3}>
                <Typography color='textPrimary' variant='h5'>
                    {title}
                </Typography>
            </Box>
            <Box fontSize={20} mb={12}>
                <Typography color='textSecondary' variant='h6'>
                    {subtitle}
                </Typography>
            </Box>
            <Box
                display='flex'
                style={{
                    maxWidth: 'calc(100vw - 48px)',
                    overflowY: 'hidden'
                }}
            >
                {getButtons(buttons).map((button, index) => {
                    const value =
                        typeof button === 'string' ? button : button.value
                    return (
                        <Box mr={4} key={index}>
                            <Button
                                color='primary'
                                onClick={() =>
                                    setCategory(
                                        value === 'Show all' ? null : value
                                    )
                                }
                                style={{
                                    border: '2px solid',
                                    borderRadius: 36,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    height: 49,
                                    padding: '15px 32px',
                                    textTransform: 'unset',
                                    whiteSpace: 'nowrap'
                                }}
                                variant={
                                    value === (category || 'Show all')
                                        ? 'contained'
                                        : 'outlined'
                                }
                            >
                                {value}
                            </Button>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}
