import { Box } from '@chakra-ui/react'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { ArticleRelevantLink } from 'common/components/sharedLibrary'
import { useDesktop } from 'common/hooks/useDesktop'
import { Link } from 'common/utils/getLink'
import React from 'react'

/* eslint-disable-next-line */
export interface ArticleRelevantProps {
    links: Link[]
}

export const ArticleRelevant: React.FC<ArticleRelevantProps> = ({ links }) => {
    const desktop = useDesktop()
    return (
        <Box position='sticky' top={32} marginBottom={desktop ? 32 : 0}>
            {!desktop && <Spacer variant='md' />}
            <Text
                box={{ borderBottom: '2px solid #4DB7AB', mb: 5, pb: 6 }}
                label='Relevant articles'
                variant='h6'
            />
            {links.map((link, index) => (
                <ArticleRelevantLink key={index} {...link} />
            ))}
        </Box>
    )
}

export default ArticleRelevant
