import { Box, Typography } from '@material-ui/core'
import React from 'react'

export interface TagProps {
    bgcolor?: string
    color?: string
    label: string
}

export const Tag: React.FC<TagProps> = ({ bgcolor, color, label }) => {
    return (
        <Box
            color={color || '#4DB7AB'}
            display='inline-block'
            bgcolor={bgcolor || '#E7F8F8'}
            borderRadius={24}
            px={3}
            py={2}
        >
            <Typography
                style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 10,
                    lineHeight: '120%',
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'uppercase'
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}
