import { Link } from 'common/components/atoms/Link'
import { Tag, TagProps } from 'common/components/sharedLibrary'
import React from 'react'
export interface BlogTagProps extends TagProps {
    url?: string
}

const categories: { [index: string]: { color: string; bgcolor: string } } = {
    'Life Insurance': {
        color: '#2196F3',
        bgcolor: '#e6f5ff'
    },
    Health: {
        color: '#039FBF',
        bgcolor: '#e2f8fa'
    },
    Pensions: {
        color: '#717FE2',
        bgcolor: '#f1f3fe'
    },
    Investments: {
        color: '#9D47B8',
        bgcolor: '#f9ecf9'
    }
}

export const BlogTag: React.FC<BlogTagProps> = ({ url, ...props }) => {
    if (url)
        return (
            <Link path={url}>
                <Tag {...props} {...categories[props.label]} />
            </Link>
        )
    return <Tag {...props} {...categories[props.label]} />
}
