import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import { BlogTag } from 'common/components/molecules/BlogTag'
import React, { useState } from 'react'
import ClampLines from 'react-clamp-lines'
import { ArticleCardProps } from '../article-card/article-card'
import { ReactComponent as Blob } from './article-featured-blob.svg'
export interface ArticleFeaturedProps extends ArticleCardProps {
    heading?: string
}

export const ArticleFeatured: React.FC<ArticleFeaturedProps> = ({
    heading = 'Featured Article',
    image,
    tag,
    title,
    text = '',
    url,
}) => {
    const [active, setActive] = useState(false)
    return (
        <Link path={`/blog/${url}`}>
            <Box
                mt={24}
                onMouseOver={() => setActive(true)}
                onMouseOut={() => setActive(false)}
                style={{ cursor: active ? 'pointer' : undefined }}
                component='article'
            >
                <Box
                    borderLeft='2px solid #4DB7AB'
                    fontSize={16}
                    pl={6}
                    mb={12}
                >
                    <Typography color='textPrimary' variant='overline'>
                        {heading}
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Box position='relative' paddingLeft={'27px'}>
                            <Box
                                borderRadius={20}
                                style={{
                                    backgroundImage: `url(${image}?format=auto&w=380)`,
                                    backgroundSize: '100% 100%',
                                    backgroundRepeat: 'no-repeat',
                                }}
                                paddingTop='90%'
                                width='100%'
                            />
                            <Blob
                                style={{
                                    width: '120.55%',
                                    height: '129.39%',
                                    position: 'absolute',
                                    left: '-10.87%',
                                    top: '-14.29%',
                                    zIndex: -1,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={1} />
                    </Hidden>
                    <Grid item xs={12} md={7}>
                        <Box mb={6}>
                            <Hidden mdUp>
                                <Box mt={8} />
                            </Hidden>
                            <BlogTag label={tag} />
                        </Box>
                        <Box mb={3}>
                            <Typography
                                color='textPrimary'
                                variant='h4'
                                style={{
                                    textDecoration: active
                                        ? 'underline'
                                        : undefined,
                                }}
                            >
                                <ClampLines
                                    buttons={false}
                                    id='Featured Title'
                                    lines={2}
                                    text={title}
                                />
                            </Typography>
                        </Box>
                        <Box fontSize={20} mb={16}>
                            <Typography color='textSecondary' variant='h6'>
                                <ClampLines
                                    buttons={false}
                                    id='Featured Subtitle'
                                    lines={4}
                                    text={text}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Link>
    )
}
