import { Text } from 'common/components/molecules/Text'
import React from 'react'
import styled from 'styled-components'

const Output = styled.div`
    table {
        border-spacing: 0;
        margin-bottom: 16px;
        margin-top: 16px;
        td {
            height: 80px;
            padding: 16px;
        }
        width: 100%;
    }
    tbody {
        & > :nth-child(1) {
            color: #3a3160;
            font-size: 18px;
        }
        & > :nth-child(n + 2) {
            font-size: 16px;
            color: rgb(118, 111, 144);
        }
        & > :nth-child(even) {
            background: #f0f3fd;
        }
    }
    p,
    li {
        color: #766f90;
        font-size: 16px;
        line-height: 25px;
    }
    h2 {
        color: #3a3160;
        font-size: 24px;
    }
    h3 {
        color: #3a3160;
        font-size: 20px;
    }
    img {
        max-width: 100%;
    }
    // ol {
    //     list-style: none;
    //     counter-reset: my-awesome-counter;
    //     padding-left: 0px;
    // }
    // ol li {
    //     counter-increment: my-awesome-counter;
    // }
    // ol li::marker {
    //     color: #4db7ab;
    //     content: counter(my-awesome-counter) '. ';
    //     font-weight: bold;

    // }
`

export interface ArticleBodyProps {
    text: string
}

export const ArticleBody: React.FC<ArticleBodyProps> = ({ text }) => {
    return (
        <Output>
            <Text html label={text} variant='body2' />
        </Output>
    )
}
