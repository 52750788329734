import { Box, Typography, useTheme } from '@material-ui/core'
import { BlogTag } from 'common/components/molecules/BlogTag'
import React, { useState } from 'react'
import ClampLines from 'react-clamp-lines'
import { makeStyles } from '@material-ui/core/styles'

export interface ArticleCardProps {
    image: string
    tag: string
    title: string
    text?: string
    url: string
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
    image,
    tag,
    title
}) => {
    const useStyles = makeStyles({
        clampLines: {
            whiteSpace: 'normal'
        }
    })
    const classes = useStyles()
    const theme = useTheme()
    const [active, setActive] = useState(false)
    const imagePath = `${image}?format=auto&w=360`

    return (
        <Box
            bgcolor={theme.palette.background.paper}
            borderRadius={20}
            onMouseOver={() => setActive(true)}
            onMouseOut={() => setActive(false)}
            overflow='hidden'
            style={{ cursor: active ? 'pointer' : undefined }}
            component='article'
        >
            <Box
                paddingTop='66%'
                style={{
                    backgroundImage: `url(${imagePath})`,
                    backgroundSize: 'cover'
                }}
            />
            <Box px={7} pt={5} pb={10}>
                <Box mb={5}>
                    <BlogTag label={tag} />
                </Box>
                <Box mb={6}>
                    <Typography
                        color='textPrimary'
                        variant='h6'
                        component={'h3'}
                        style={{
                            minHeight: 64,
                            textDecoration: active ? 'underline' : undefined
                        }}
                    >
                        <ClampLines
                            buttons={false}
                            id='Card Title'
                            lines={2}
                            text={title}
                            className={classes.clampLines}
                        />
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
